<template>
  <div class="app-media-viewer">
    <!-- begin::Multiple Media -->
    <template v-if="isArray">
      <div class="images">
        <b-row no-gutters>
          <template v-for="(i, index) in value">
            <b-col
              :key="index"
              :cols="grid.cols"
              :md="grid.md"
              :lg="grid.lg"
              class="mb-4 mb-md-0 p-md-2 app-media-viewer__media-container cursor-pointer"
              @click="showMultipleMedia(value, i)"
            >
              <b-img
                v-if="checkIsImage(i.mediaType)"
                :src="i.mediaPath"
                class="multiple-media media w-100"
              >
              </b-img>
              <video
                v-if="checkIsVideo(i.mediaType)"
                class="multiple-media media w-100"
              >
                <source :src="i.mediaPath" />
              </video>

              <div class="middle">
                <div class="text">{{ $t('label.clickToView') }}</div>
              </div>
            </b-col>
          </template>
        </b-row>
      </div>
    </template>
    <!-- end::Multiple Media -->

    <!-- begin::Single Media -->
    <template v-else>
      <div
        v-if="value && value.mediaPath"
        class="app-media-viewer__media-container images cursor-pointer"
        @click="showSingleMedia(value)"
      >
        <b-img
          v-if="checkIsImage(value.mediaType)"
          :src="value.mediaPath"
          class="w-100 single-media media"
          :class="imageClass"
        >
        </b-img>

        <video
          v-if="checkIsVideo(value.mediaType)"
          class="single-media media w-100"
        >
          <source :src="value.mediaPath" />
        </video>
        <div class="middle">
          <div class="text">
            {{ $t('label.clickToView') }}
          </div>
        </div>
      </div>
      <slot v-else name="placeholder" />
    </template>
    <!-- end::Single Media -->

    <!-- begin::Video Viewer -->
    <transition name="fade">
      <div
        v-if="videoOverlay"
        style=""
        class="app-media-viewer__video-viewer"
        @click="closeVideoOverlay"
      >
        <div
          class="app-media-viewer__video-viewer-button"
          @click="closeVideoOverlay"
        >
          <i class="flaticon2-cancel-music icon-nm text-gray"></i>
        </div>
        <div
          class="app-media-viewer__video-viewer-content d-flex justify-content-center align-items-center"
        >
          <video controls>
            <source :src="selectedVideoPath" />
          </video>
        </div>
      </div>
    </transition>
    <!-- end::Video Viewer -->

    <!-- begin:: Media is empty -->
    <span v-if="!value">{{ $t('message.thereAreNoMediaAssetToShow') }}</span>
    <!-- end:: Media is empty -->
  </div>
</template>

<script>
  import { MediaType } from '@/core/constants/enums';

  export default {
    name: 'AppMediaViewer',
    props: {
      value: {
        type: [Object, Array]
      },
      imageClass: {
        type: String,
        default: ''
      },
      grid: {
        type: Object,
        default: () => {
          return {
            cols: '12',
            md: '6',
            lg: '4'
          };
        }
      }
    },
    data: () => ({
      mediaType: MediaType,
      videoOverlay: false,
      selectedVideoPath: null,
      displayVideos: []
    }),
    computed: {
      isArray() {
        return Array.isArray(this.value);
      }
    },
    methods: {
      checkIsImage(type) {
        return [MediaType.IMAGE, 'image'].includes(type);
      },
      checkIsVideo(type) {
        return [MediaType.VIDEO, 'video'].includes(type);
      },
      showSingleMedia(value) {
        if (this.checkIsImage(value.mediaType)) {
          this.$viewerApi({
            images: [value.mediaPath]
          });
        } else if (this.checkIsVideo(value.mediaType)) {
          this.selectedVideoPath = value.mediaPath;
          this.videoOverlay = true;
        }
      },
      showMultipleMedia(value, item) {
        if (this.checkIsImage(item.mediaType)) {
          let list = value.map((x) => {
            return x.mediaPath;
          });

          this.$viewerApi({
            images: list
          });
        } else if (this.checkIsVideo(item.mediaType)) {
          this.selectedVideoPath = item.mediaPath;
          this.videoOverlay = true;
        }
      },
      closeVideoOverlay() {
        this.videoOverlay = false;
        this.selectedVideoPath = null;
      }
    }
  };
</script>

<style lang="scss">
  .app-media-viewer {
    .single-media {
      object-fit: cover;
      max-height: 375px;
    }
    .multiple-media {
      object-fit: cover;
      height: 150px;
    }

    .app-media-viewer__media-container {
      position: relative;
    }

    .media {
      opacity: 1;
      display: block;
      transition: 0.3s ease;
      backface-visibility: hidden;
    }

    .middle {
      min-width: 100px;
      transition: 0.3s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    .app-media-viewer__media-container:hover .media {
      opacity: 0.2;
    }

    .app-media-viewer__media-container:hover .middle {
      opacity: 1;
    }

    .text {
      text-align: center;
      color: #000000;
      font-size: 14px;
      padding: 4px;
    }

    .app-media-viewer__video-viewer {
      inset: 0px;
      z-index: 1000;
      background: rgb(0, 0, 0, 0.5);
      position: fixed;
      height: 100%;
      width: 100%;
      transition: all 0.3s;
      .app-media-viewer__video-viewer-button {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        cursor: pointer;
        height: 80px;
        overflow: hidden;
        position: absolute;
        right: -40px;
        top: -40px;
        -webkit-transition: background-color 0.15s;
        transition: background-color 0.15s;
        width: 80px;
        > i {
          bottom: 15px;
          left: 19px;
          position: absolute;
        }
      }
      .app-media-viewer__video-viewer-content {
        width: 100%;
        height: 100%;
        > video {
          max-width: 25%;
        }
      }
    }
  }
</style>
