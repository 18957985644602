<template>
  <div>
    <i v-if="value" class="fas fa-check-circle text-success icon-lg"></i>
    <i v-else class="far fa-circle icon-lg"></i>
  </div>
</template>

<script>
  export default {
    name: 'AppCheckCircle',
    props: {
      value: {
        type: Boolean,
        require: true,
        default: false
      }
    }
  };
</script>

<style></style>
