<template>
  <b-card>
    <!-- begin:: Card Header -->
    <template #header>
      <h3 class="text-capitalize">{{ $t('label.media') }}</h3>
    </template>
    <!-- end:: Card Header -->

    <!-- begin:: Card Body -->
    <AppMediaViewer :value="newsFeedObj.media"></AppMediaViewer>
    <!-- end:: Card Body -->
  </b-card>
</template>

<script>
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';

  export default {
    name: 'CommunityMediaCard',
    components: {
      AppMediaViewer
    },
    props: {
      newsFeedObj: {
        type: Object,
        default: () => {}
      }
    }
  };
</script>

<style></style>
