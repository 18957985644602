<template>
  <b-card class="post-overview-job">
    <h3 class="text-capitalize h2 mb-8">{{ $t('label.overview') }}</h3>

    <section class="d-flex mb-8">
      <div>
        <AppMediaViewer
          v-if="newsFeedObj.user.logoImage"
          :value="newsFeedObj.user.logoImage"
          imageClass="post-overview-job__job-cover-file"
        ></AppMediaViewer>

        <b-avatar
          v-else
          square
          :variant="avatarVariant"
          size="72px"
          :text="shortName"
        ></b-avatar>
      </div>
      <div class="p-4">
        <div class="font-weight-bolder text-capitalize mb-1">
          {{ $t('label.name') }}:
        </div>
        <div class="font-weight-bolder h4 text-primary">
          <AppFeatureDetailsRelatedButton
            :value="{ id: newsFeedObj.user.id, name: newsFeedObj.user.name }"
            :config="{ to: routeName.PROFILE_DETAILS_DETAILS }"
          ></AppFeatureDetailsRelatedButton>
        </div>
      </div>
    </section>

    <section class="mb-8">
      <template v-for="(i, index) in basicInfoList">
        <div :key="i + index" class="mb-4">
          <div class="font-weight-bolder text-capitalize mb-1">
            {{ $t(`label.${i.name}`) }}:
          </div>
          <component
            v-if="i.component"
            :is="i.component"
            :value="newsFeedObj[i.key]"
          >
          </component>
          <div v-else class="text-end text-gray-600" :class="i.class">
            {{ newsFeedObj[i.key] || '-' }}
          </div>
        </div>
      </template>
    </section>
  </b-card>
</template>

<script>
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'CommunityPostOverviewCard',
    components: {
      AppMediaViewer,
      AppCheckCircle,
      AppFeatureDetailsRelatedButton
    },
    props: {
      value: {
        type: Number,
        default: 1
      },
      newsFeedObj: {
        type: Object,
        default: () => {
          return {
            user: {}
          };
        }
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      basicInfoList: [
        {
          key: 'like',
          name: 'like'
        },
        {
          key: 'liked',
          name: 'liked',
          component: 'AppCheckCircle'
        },
        {
          key: 'comments',
          name: 'comments'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        }
      ]
    }),
    computed: {
      shortName() {
        let name = this.newsFeedObj.user.name;
        let nameArray = name ? name.split(' ') : '';
        let shortName = '';

        if (name) {
          if (nameArray.length > 1) {
            shortName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
          } else {
            shortName = nameArray[0].charAt(0);
          }
        }

        return shortName;
      },
      avatarVariant() {
        const variants = [
          'primary',
          'secondary',
          'danger',
          'warning',
          'success',
          'info'
        ];

        let random = Math.floor(Math.random() * variants.length);

        return random, variants[random];
      }
    }
  };
</script>

<style lang="scss">
  .post-details_item-list-group {
    .list-group-item.list-group-item-action.active {
      background: unset !important;
      border-color: unset !important;
      color: var(--primary);
      .icon {
        color: var(--primary);
      }
    }
  }

  .post-overview-job {
    .post-overview-job__job-cover-file {
      height: 72px !important;
      width: 72px !important;
      object-fit: cover !important;
    }
  }
</style>
