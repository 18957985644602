<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!-- begin: Overview -->
        <CommunityPostOverviewCard
          v-model="selectedPostDetailsItem"
          :newsFeedObj="newsFeedObj"
          class="mb-6"
        ></CommunityPostOverviewCard>
        <!-- begin: Overview -->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <CommunityMediaCard :newsFeedObj="newsFeedObj"></CommunityMediaCard>
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import CommunityPostOverviewCard from '@/views/components/community-post/CommunityPostOverviewCard';
  import CommunityMediaCard from '@/views/components/community-post/CommunityMediaCard';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    NEWS_FEEDS_GET_NEWS_FEED,
    NEWS_FEEDS_INITIAL_GET_NEWS_FEED_STATE
  } from '@/core/store/news-feeds.module';

  export default {
    name: 'CommunityPostDetails',
    mixins: [commonMixin],
    components: {
      CommunityPostOverviewCard,
      CommunityMediaCard
    },
    data: () => ({
      id: null,
      newsFeedObj: {
        user: {}
      },
      selectedPostDetailsItem: 1
    }),
    computed: {
      newsFeedsComplete() {
        return this.$store.state.newsFeeds.newsFeed.complete;
      }
    },
    watch: {
      newsFeedsComplete() {
        let response = this.$store.state.newsFeeds.newsFeed;
        let title = i18nHelper.getMessage('label.getCommunityPostDetails');
        let initialStateAction = NEWS_FEEDS_INITIAL_GET_NEWS_FEED_STATE;
        let successAction = (response) => {
          this.importNewsFeedObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialPostDetails();
    },
    methods: {
      refreshProfileDetailsAction() {
        this.getNewsFeed(this.id);
      },
      importNewsFeedObj(response) {
        let data = response.data;

        this.newsFeedObj = data;
      },
      getNewsFeed(id) {
        this.$store.dispatch(NEWS_FEEDS_GET_NEWS_FEED, {
          id
        });
      },
      initialPostDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.communityPost'),
              route: { name: ROUTE_NAME.COMMUNITY_POST }
            },
            { title: i18nHelper.getMessage('label.communityPostDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getNewsFeed(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
